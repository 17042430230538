import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { DateRangeContext } from './DateRangeContext';

function TopicItems() {
    const [items, setItems] = useState([]);
    const [input, setInput] = useState('');
    const [locked, setLocked] = useState(false);
    const { dateRange } = useContext(DateRangeContext);

    const fetchTopicList = async () => {
        axios.get('/dev/upload/getTopicList')
        .then(response => {
          console.log(response)
          setItems(response.data.topicList);
        })
        .catch(error => console.error('Error:', error));
    };

    const setTopicList = async (items) => {
        if (items.length > 0) {
            axios.post('/dev/upload/setTopicList', {
                topicList: items,
            })
            .then(response => {
                console.log('Updated successfully:', response);
            })
            .catch(error => console.error('Error:', error));
        }
    };

    const handleAdd = async () => {
        if (!locked && input.trim() !== '' && items.length < 10) {
            const newItems = [...items, input.trim()];
            setItems(newItems);
            setInput('');
            setTopicList(newItems);
        }
    };

    const handleDelete = (index) => {
        if (!locked) {
            const newItems = items.filter((item, idx) => idx !== index);
            setItems(newItems);
            setTopicList(newItems);
        }
    };

    const handleInputChange = (event) => {
        if (!locked) setInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAdd();
        }
    };

    const toggleLock = () => {
        setLocked(!locked);
    };

    useEffect(() => {
        fetchTopicList();
    }, [dateRange]);

    return (
        <div style={{ position: 'relative'}}>
            {locked && <div style={{
                position: 'absolute', 
                top: -15, left: -15, right: -15, bottom: -15, 
                borderRadius: "10px",
                backgroundColor: 'rgba(0,0,0, .2)',
                zIndex: 1,
            }}></div>}
            {/* <IconButton 
                onClick={toggleLock} 
                style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}
            >
                {locked ? <LockIcon /> : <LockOpenIcon />}
            </IconButton> */}
            <Typography variant="h4" sx={{ mt: 2, mb:2}}>
                1. Specify your preferred Topics
            </Typography>
            <TextField
                label="Add Item"
                variant="outlined"
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                helperText={items.length >= 10 ? "Maximum items reached" : ""}
                error={items.length >= 10}
                disabled={locked}
            />
            <Button onClick={handleAdd} disabled={locked || input.trim() === '' || items.length >= 10}>
                Add
            </Button>
            <List sx={{mt:2, mb:2, borderRadius: '10px', border: '1px solid #ccc'}}>
                {items.map((item, index) => (
                    <ListItem key={index} secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)} disabled={locked}>
                            <DeleteIcon />
                        </IconButton>
                    }>
                        <ListItemText primary={item} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default TopicItems;
