import * as React from 'react';
import { useState, useCallback, useEffect, useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';
import {
  ThemeProvider, Paper, Button, Typography, TextField, List, ListItem,
  ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton,
  Radio, Box, FormControl, InputLabel, MenuItem, Select, Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Papa from 'papaparse';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import AlertDialog from './AlertDialog';
import { DateRangeContext } from './DateRangeContext';
import { SubmitContext } from './SubmitContext';
import TopicItems from './TopicItems';
import CircularProgressWithLabel from './helpers/CircularProgressWithLabel'



export default function Upload() {

  const { isSubmitted, triggerSubmit } = useContext(SubmitContext);
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [tableState, setTableState] = useState(false);
  const [columnNames, setColumnNames] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  //const [connectionString, setConnectionString] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadProgress, setUploadProgress] = useState({})
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentFile, setCurrentFile] = useState('');
  const { dateRange } = useContext(DateRangeContext);
  const [columnToCategoryMap, setColumnToCategoryMap] = useState({});
  const newCategoryOptions = [ "ID", "Country", "Date", "Comment", "NPS", "None"];
  const excludedOptions = ["None"]; 
  const [errorMessage, setErrorMessage] = useState('');
  const [cost, setCost] = useState(0); // Assuming cost is a numeric value and initialized to 0
  const [isUploading, setIsUploading] = useState(false)
 





  const showConfirmation = (action, fileName) => {
    setCurrentAction(action);
    setCurrentFile(fileName);
    setAlertOpen(true);
  };

  const handleAgree = () => {
    if (currentAction === 'delete') {
      handleRemoveFile(currentFile);
    } else if (currentAction === 'activate') {
      handleRadioChange(currentFile);
    }
    setAlertOpen(false);
  };

  const handleDisagree = () => {
    setAlertOpen(false);
  };



  useEffect(() => {
    Promise.all([fetchUploadedFiles(), fetchActiveFile()]).then(() => {
    }).catch(console.error);
  }, [dateRange]); 

  useEffect(() => {
    const token = localStorage.getItem('token');
    uploadedFiles.forEach(file => {
    startStatusPolling(file, token);
    });
  }, [uploadedFiles, dateRange]); 

  
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
      // Check if the file extension is .csv
    if (!file.name.toLowerCase().endsWith('.csv')) {
      setErrorMessage('Error! The file you tried to upload is not a .CSV File. Please Upload a .CSV file.')// Alert the user
      return;
    }

    setSelectedFile(file);
    setFileName(file.name);
    setTableState(true);
  
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      quoteChar: '"',
      escapeChar: '"',
      complete: (results) => {
        console.log('Parsed Results:', results);
        if (results.data.length > 0) {
          console.log(Object.keys(results.data[0]))
          setColumnNames(Object.keys(results.data[0]));
          setParsedData(results.data); 
          setCost(results.data.length);

          const newMap = Object.keys(results.data[0]).reduce((acc, cur) => {
            acc[cur] = ""; 
            return acc;
          }, {});
          setColumnToCategoryMap(newMap);
        }
      }
    });
  }, []);
  
  const getAvailableOptions = (currentColumnName) => {
    const selectedCategories = Object.values(columnToCategoryMap);
    
    // Filter options to exclude already selected ones, but include excludedOptions and the current column's selected option
    return newCategoryOptions.filter(option =>
      excludedOptions.includes(option) || // Always include excludedOptions
      !selectedCategories.includes(option) || // Exclude options that are already selected
      columnToCategoryMap[currentColumnName] === option // Include the current column's selected option
    );
  };
  
  const handleCategoryChange = (columnName, newCategory) => {
    setColumnToCategoryMap(prevMap => {
      // Optionally, here you can handle removing or adjusting other mappings if needed
      const updatedMap = { ...prevMap, [columnName]: newCategory };
      return updatedMap;
    });
  };
  
  const validateCategorySelections = () => {
    // Get all categories that need to be used
    const necessaryCategories = newCategoryOptions.filter(option => !excludedOptions.includes(option));
  
    // Get all categories that have been selected
    const selectedCategories = Object.values(columnToCategoryMap);
  
    // Check if every necessary category has been selected
    const allNecessarySelected = necessaryCategories.every(category => selectedCategories.includes(category));
  
    return allNecessarySelected;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv'});

  const handleFileUpload = () => {

    if (isUploading) {
      return;
    }
    setIsUploading(true);

    const token = localStorage.getItem('token');

    if (!selectedFile) {
      setErrorMessage("Error: No File Selected!");
      setIsUploading(false);
      return; // Stop the upload process
    }

    setUploadStatus(prevStatus => ({ ...prevStatus, [selectedFile.name]: 'uploading' }));

  const allCategoriesUsed = validateCategorySelections();

  
  if (!allCategoriesUsed) {
    setErrorMessage("Error: Not all category selections have been used. Please ensure each category is selected at least once.");
    setIsUploading(false);
    return; // Stop the upload process
  }

  if (uploadedFiles.includes(selectedFile.name)) {
    setErrorMessage("Error: This file has already been uploaded.");
    setIsUploading(false);
    return; // Stop the upload process
  }


    // Transform parsedData to use new column names based on columnToCategoryMap
    const transformedData = parsedData.map(row => {
      const transformedRow = {};
      Object.keys(row).forEach(originalKey => {
        const newKey = columnToCategoryMap[originalKey] || originalKey; // Fallback to original key if no mapping exists
        transformedRow[newKey] = row[originalKey];
      });
      return transformedRow;
    });
    
    // Check if we have parsed data to upload
    if (transformedData.length > 0) {
      axios.post('/api/dev/upload/csvfile', {
        data: transformedData,
        uploadedFiles: [...uploadedFiles, selectedFile.name],
        token: token,
        filename: selectedFile.name,
      })
      .then(() => {
        // Handle successful upload here
        setUploadedFiles(prevFiles => [...prevFiles, selectedFile.name]);
        startStatusPolling(selectedFile.name, token);
        setTableState(false);
        triggerSubmit();
        setErrorMessage('');
        setIsUploading(false);
      })
      .catch(error => {
        if (error.response) {
          setErrorMessage(`Error: ${error.response?.data?.error || "An error occurred during the file upload."}`);
          setIsUploading(false);
        }
      });
    }
  };
  

  const handleRadioChange = (fileName) => {
    setSelectedRadio(fileName);
    console.log("Handle Radio Change: ", fileName);
    setActiveFile(fileName);
  };

  const startStatusPolling = (fileName, token) => {
    // Define a function for polling
    const pollStatus = () => {
        axios.get('/api/dev/getUploadStatus', {
            params: { fileName, token }
        })
        .then(response => {
            const status = response.data.status;
            const progress = response.data.progress;
            console.log("PROGRESS")
            console.log(progress)
            //if (dataLength != 0) setProgress(Math.floor(progress / dataLength));
            setUploadStatus(prevStatus => ({ ...prevStatus, [fileName]: status }));
            setUploadProgress(prevStatus => ({ ...prevStatus, [fileName]: progress }))

            if (status === 'completed' || status === 'error' || status === 'deleting') {
              clearInterval(intervalId);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            clearInterval(intervalId);
        });
    };

    pollStatus();
    const intervalId = setInterval(pollStatus, 5000);
};

  

  const setActiveFile = (fileName) => {
    console.log("Selected Radio (setActiveFile): ",  fileName);
    axios.post('/api/dev/setActiveFile', { 
      activeFile: fileName,
      token: localStorage.getItem('token')
    })
    .then(response => console.log("Active file set:", response))
    .catch(error => console.error('Error:', error));
  };

  const fetchActiveFile = () => {
    axios.get('/api/dev/getActiveFile', {
      params: {
        token: localStorage.getItem('token'),
      },
    })
    .then((response) => {
      console.log("fetched Active file:", response);
      setSelectedRadio(response.data.activeFile);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };
  
  const fetchUploadedFiles = () => {
    axios.get('/api/dev/getUploadedFiles', { 
      params: {
        token: localStorage.getItem('token'),
      },
    })
    .then(response => {
      console.log(response)
      setUploadedFiles(response.data.uploadedFiles);
    })
    .catch(error => console.error('Error:', error));
  };
  

  const handleRemoveFile = (fileName) => {;
    setUploadStatus(prevStatus => ({ ...prevStatus, [fileName]: 'deleting' }));
    const updatedFiles = uploadedFiles.filter(file => file !== fileName);
    if (selectedRadio === fileName){
      setActiveFile("");
    }
    
    axios.post('/api/dev/updateUploadedFiles', {
      uploadedFiles: updatedFiles,
      token: localStorage.getItem('token'),
      filename: fileName
    })
    .then(response => {
      console.log("Updated files list sent to backend:", response);
      // Update local state only after confirmation from backend
      setUploadedFiles(updatedFiles);
    })
    .catch(error => console.error('Error:', error));
  };
  



  const dropzoneStyle = {
    border: '2px dashed #ccc',
    padding: '50px',
    marginTop: '20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isDragActive ? '#eeeeee' : '#fafafa',
    color: isDragActive ? '#2196f3' : 'black',
  };

  return (
    <ThemeProvider theme={theme}>
    {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TopicItems />
          </Grid>      
        </Grid>
      </Paper>
    </Container> */}

      {/* File Upload Section */}
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="h4" component="h1" gutterBottom>
              2. Upload your .CSV files
            </Typography>
            <Box {...getRootProps()} sx={dropzoneStyle}>
              <input {...getInputProps()} />
              {
                isDragActive 
                ? <Typography variant="subtitle1">Drop the files here ...</Typography> 
                : <Typography variant="subtitle1">Drag 'n' drop some files here, or click to select files</Typography>
              }
            </Box>
              {tableState && (
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  <strong>Filename:</strong> {fileName}
                </Typography>
              )}
              {tableState && (
                              <> 
                                
                                <Typography variant="h4" sx={{ mt: 10, mb:2}}>
                                  Map your Column Names to our predefined categories!
                                </Typography>
                                <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table>
                                  <TableHead>
                                    {/*                                  
                                    {columnNames.map((columnName, index) => (
                                      <TableRow>
                                      <TableCell key={index}>
                                        <strong>{columnName || "None"}</strong>
                                      </TableCell>
                                    </TableRow>
                                    ))}; */}                    
                                    <TableRow>
                                    {columnNames.map((columnName, index) => (
                                      <TableCell key={index}>
                                        <Typography variant="custom" sx={{ mt: 2, mb: 2, fontWeight: "bold"}}>{columnName || "None"}</Typography>
                                        <FormControl sx={{ mt: 2, mb:0}} style={{minWidth: 100}}>
                                          <InputLabel id={`${columnName}-header-label`}>Select</InputLabel>
                                          <Select
                                            labelId={`${columnName}-header-label`}
                                            id={`${columnName}-header-select`}
                                            value={columnToCategoryMap[columnName] || ''}
                                            label={`Map "${columnName}" to`}
                                            onChange={(e) => handleCategoryChange(columnName, e.target.value)}
                                            size="small"
                                          >
                                            {getAvailableOptions(columnName).map(option => (
                                              <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </TableCell>
                                    ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  {parsedData.slice(0, 3).map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                      {columnNames.map((columnName, colIndex) => {
                                        // Function to truncate text to a specific length
                                        const truncateText = (text, maxLength) => {
                                          if (text.length <= maxLength) return text;
                                          return `${text.substring(0, maxLength)}...`; // Add ellipsis to indicate text is shortened
                                        };

                                        // Apply truncate function to each cell's content
                                        const cellText = row[columnName].toString(); // Ensure the value is a string
                                        const truncatedText = truncateText(cellText, 50); // Example: Limit to 10 characters

                                        return (
                                          <TableCell key={colIndex}>{truncatedText}</TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              </>
              )}
              {errorMessage && (
                        <Alert  severity={"error"} sx={{ width: '100%', mt: 2 }}>
                            {errorMessage}
                        </Alert>
                    )}
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFileUpload}
                >
                  {isUploading ? 'Uploading...' : 'Upload File'}
                </Button>
                <Typography sx={{ ml: 2 }}> {/* Add margin to the left to separate the elements */}
                  Cost: {cost} ₹
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>

    
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1" gutterBottom>
                3. Activate Available Dataset
              </Typography>
              <List>
                {uploadedFiles.map((fileName, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        {uploadStatus[fileName] !== 'completed' ? <CircularProgressWithLabel value={uploadProgress[fileName]} /> : <FolderIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={fileName} secondary={uploadStatus[fileName]} />
                    <ListItemSecondaryAction>
                      <Radio
                        checked={selectedRadio === fileName}
                        onChange={() => showConfirmation('activate', fileName)}
                        disabled={uploadStatus[fileName] !== 'completed'}
                      />
                      <IconButton 
                      edge="end" 
                      aria-label="delete" 
                      onClick={() => showConfirmation('delete', fileName)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <AlertDialog
        open={alertOpen}
        title="Confirm Action"
        message={`Are you sure you want to ${currentAction} "${currentFile}"?`}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
    </ThemeProvider>
  );
}
